export class Tabs {

  constructor() {

    this.buttons = document.querySelectorAll('.tabs a')
    this.tabs = document.querySelectorAll('.tab-content')

    this.buttons.forEach(b => {

      b.addEventListener('click', (event) => {

        let id_tab = b.dataset.tab

        this.reset()

        b.classList.add('active')
        document.querySelector('#' + id_tab).classList.add('active')

      })

    })

  }

  reset() {

    this.buttons.forEach(b => {
      b.classList.remove('active')
    })

    this.tabs.forEach(t => {
      t.classList.remove('active')
    })

  }

}
