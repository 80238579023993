import gsap from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export class Agenda {
  constructor() {
    document.querySelectorAll(".s-agenda").forEach((container) => {
      const wrapper = container.querySelector(".agenda-grid");


      if (wrapper) {
        let mm = gsap.matchMedia(),
          breakPoint = 300;
        mm.add({
          isDesktop: `(min-width: ${breakPoint}px) and (prefers-reduced-motion: no-preference)`,
          isMobile: `(max-width: ${breakPoint - 1}px) and (prefers-reduced-motion: no-preference)`
        }, (context) => {
          let {isDesktop, isMobile} = context.conditions

          if (isDesktop) {
            gsap.to(wrapper, {
              x: () => -1 * ( (wrapper.scrollWidth - container.offsetWidth) ),
              ease: "none",
              scrollTrigger: {
                trigger: container,
                pin: true,
                markers: false,
                start: 'top 60px',
                scrub: 1,
                end: () => "+="+ (wrapper.scrollWidth),
                onUpdate: (self) => {
                  if (window.innerWidth < 300) {
                    self.kill();
                  }
                }
              }
            });
          }

          return () => {
          }
        });
      }
    });
  }
}
