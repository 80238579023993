import '@css/main.scss';
import '@js/bootstrap';

import {Header} from '@js/utils/Header';
import {Slider} from '@js/utils/Slider';
import {Accordion} from "@js/utils/Accordion";
import {Archive} from "@js/utils/Archive";
import {Popup} from "./utils/Popup";
import {Tabs} from "./utils/Tabs";
import {Agenda} from "./utils/Agenda";

window.onload = () => {

  new Header()
  new Slider()
  new Accordion()
  new Archive()
  new Popup()
  new Tabs()
  new Agenda()

  const anchors = document.querySelectorAll('.tabs > a[href*="#"]')

  anchors.forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      if (window.matchMedia("(min-width: 768px)").matches) {
        e.preventDefault()
      }
    })
  })

}
