export class Archive {
  constructor() {

    this.container = document.querySelector('*[data-archive]')
    if (!this.container) {
      return;
    }
    this.type = this.container.getAttribute('data-archive')

    this.moreBtn = this.container.querySelector('*[data-archive-btn-more]');
    this.moreBtnInitText = this.moreBtn.innerHTML
    this.filters_container = this.container.querySelector('*[data-filters]');
    this.filters_type = this.filters_container.getAttribute('data-filters');
    this.filters = this.filters_container.querySelectorAll('*[data-filter]');
    this.itemsContainer = this.container.querySelector('*[data-archive-endpoint]')
    this.init()
  }

  init() {
    this.config = this.defaults()
    this.url = `${window.location.protocol}//${window.location.hostname}/wp-json/akyos/v1/${this.type}`
    this.register()
  }

  defaults() {
    return {
      itemsperpage: parseInt(this.container.dataset.itemsperpage),
      itemsoffset: parseInt(this.container.dataset.itemsperpage),
      currentpage: 1,
      noMoreItems: false,
      currentTax: false,
    }
  }

  reset() {
    this.filters.forEach(f => f.classList.remove('active'))
    this.config = this.defaults()
    this.itemsContainer.innerHTML = "";
    this.moreBtn.innerHTML = this.moreBtnInitText;
    this.moreBtn.removeAttribute('hidden');
  }

  register() {
    this.filters.forEach(f => {
      f.addEventListener('click', (e) => {
        const id = f.dataset.filter
        e.preventDefault();
        this.reset()

        f.classList.add('active')

        this.appendSkeletons()
        this.config.currentTax = id
        this.fetch(true)
      })
    })

    this.moreBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.config.currentpage += 1;
      if (this.config.noMoreItems) {
        return;
      }

      this.fetch();
    })
  }

  fetch(reset = false) {
    const params = new URLSearchParams();
    params.append('per_page', this.config.itemsperpage)
    params.append('page', this.config.currentpage)
    if (this.config.currentTax && this.config.currentTax !== "*") {
      params.append(this.filters_type, this.config.currentTax)
    }

    fetch(`${this.url}?${params.toString()}`)
      .then((r) => r.json())
      .then(r => {

        console.log(r)

        if (reset) {
          this.itemsContainer.innerHTML = "";
        }

        if (r.length === 0 || r.length < this.config.itemsperpage) {
          this.moreBtn.innerHTML = 'Aucun résultat trouvé';
          this.config.noMoreItems = true;
          this.moreBtn.setAttribute('hidden', '')
        } else {
          this.moreBtn.removeAttribute('hidden')
        }

        if (r.length === 0 && this.config.currentpage === 1) {
          this.moreBtn.innerHTML = 'Aucun évènement';
          this.config.noMoreItems = true;
          this.moreBtn.removeAttribute('hidden')
        }

        this.appendItems(r);
      })
      .catch(r => {
        console.log(r)
        this.moreBtn.setAttribute('hidden', '')
        this.moreBtn.innerHTML = 'Aucun résultat trouvé';
        this.config.noMoreItems = true;
      })
  }

  appendSkeletons() {
    const template = document.querySelector('#skeleton');

    for (let i = 0; i < 3; i++) {
      let clone = template.content.cloneNode(true);
      this.itemsContainer.appendChild(clone)
    }

  }

  appendItems(items) {

    const template = document.querySelector('#archive-el');

    items.forEach((item) => {
      let clone = template.content.cloneNode(true);
      clone = this.itemPost(clone, item)
      this.itemsContainer.appendChild(clone)
    })

  }


  itemPost(clone, item) {

    const thumb = clone.querySelector('.c-post picture');
    const title = clone.querySelector('.c-post h3');
    const date = clone.querySelector('.c-post .c-post__date');
    const links = clone.querySelectorAll('.c-post a');

    const thumbnail = document.createElement('img')
    thumbnail.setAttribute('src', item.thumbnail)

    thumb.appendChild(thumbnail)

    if (item.post_title) {
      title.innerHTML = item.post_title
    }

    if (item.date) {
      date.innerHTML = item.event_date + " - " + item.date
    }

    if (links) {
      links.forEach((link) => {
        link.setAttribute('href', item.link)
      });
    }

    return clone
  }

}
