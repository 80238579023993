export class Popup {

  constructor() {

    const categories = document.querySelectorAll('.resume-category')

    categories.forEach(c => {

      c.addEventListener('click', (event) => {

        let id_popup = c.dataset.popup

        document.querySelector('#' + id_popup).showModal()

      })

    })

    const popups = document.querySelectorAll('.resume-popup')

    popups.forEach(p => {

      p.addEventListener('click', (event) => {

        var rect = p.getBoundingClientRect();
        var isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height &&
          rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
        if (!isInDialog) {
          p.close();
        }

      })

    })

  }

}
