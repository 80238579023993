export class Header {
  constructor() {
    this.burger()
    this.scrollHandler()
  }

  burger() {
    this.header = document.querySelector('body header')
    if (this.header) {

      const nav = document.querySelector('.header-nav__burger')
      const burger = document.querySelector('#burger')
      const menu = document.querySelector('.header-nav__nav')
      const links = document.querySelectorAll('.header-nav__nav a')

      if (burger) {

        burger.addEventListener('click', evt => {
          evt.preventDefault()
          burger.classList.toggle('active')
          nav.classList.toggle('expanded')
        })

        window.addEventListener('hashchange', function () {
          burger.classList.remove('active')
          nav.classList.remove('expanded')
        });

        window.addEventListener('resize', evt => {

          let breakpoint = burger.getAttribute('data-breakpoint')
          if (window.matchMedia("(max-width: " + breakpoint + "px)").matches) {
            menu.style.display = "none";
            burger.classList.remove('hidden');
            burger.classList.add('show');
          } else {
            menu.style.display = "block";
            burger.classList.remove('show');
            burger.classList.add('hidden');
          }

        })
      }

      const burgerSubmenus = document.querySelectorAll("#menu-menu-principal-1 > .menu-item-has-children")

      burgerSubmenus.forEach(t => {

        let trigger = t.querySelectorAll('a')[0].offsetHeight
        let height = t.querySelector('.sub-menu').offsetHeight + trigger

        t.style.setProperty('--max-height', height + 'px')
        t.style.setProperty('--init-height', trigger + 'px')

        t.addEventListener('click', e => {

          let submenus = document.querySelectorAll("#menu-menu-principal-1 > .menu-item-has-children")

          if (!t.classList.contains('open')) {
            e.preventDefault()
            submenus.forEach(s => {
              s.classList.remove('open')
            })
            t.classList.toggle('open')

          }

        })
      })

    }
  }

  scrollHandler() {

    if (document.documentElement.scrollTop || document.body.scrollTop > 0) {
      this.header.classList.add('scrolled')
    }

    document.addEventListener('scroll', () => {
      let height = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;

      if (height > 0 && !this.header.classList.contains('scrolled')) {
        this.header.classList.toggle('scrolled')
      }
      if (height === 0 && this.header.classList.contains('scrolled')) {
        this.header.classList.toggle('scrolled')
      }
    })

  }

}
